import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { registerLocaleData } from '@angular/common';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

// Angular Material handles date localization automatically based on MAT_DATE_LOCALE

@NgModule({
  imports: [CommonModule, MatDatepickerModule, MatNativeDateModule],
  declarations: [],
  exports: [],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (translationService: TranslateService) =>
        translationService?.currentLang,
      deps: [TranslateService],
    },
    {
      provide: DateAdapter,
      useFactory: (translationService: TranslateService) => {
        const lang = translationService.currentLang;
      },
      deps: [TranslateService],
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (translationService: TranslateService) => {
        const lang = translationService.currentLang;
        // Load date formats based on language
        const format = translationService.getTranslation(lang);
        return {
          parse: {
            dateInput: format, // This is how you parse input from the user
          },
          display: {
            dateInput: format, // This is how the date is displayed in the input
            monthYearLabel: 'MMMM YYYY', // Adjust as needed (e.g., 'MMM YYYY')
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
          },
        };
      },
      deps: [TranslateService],
    },
  ],
})
export class AppCalenderLocalizationModule {}
